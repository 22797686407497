import Image from 'next/legacy/image'

type Design = {
  icon:
    | 'account'
    | 'mobile-menu'
    | 'search'
    | 'x'
    | 'cart'
    | 'star'
    | 'chevron-up'
    | 'chevron-down'
    | 'chevron-right'
    | 'chevron-right-light'
    | 'check'
    | 'check-light'
    | 'success'
    | 'info'
    | 'question-mark'
    | 'stars-0.0'
    | 'stars-0.5'
    | 'stars-1.0'
    | 'stars-1.5'
    | 'stars-2.0'
    | 'stars-2.5'
    | 'stars-3.0'
    | 'stars-3.5'
    | 'stars-4.0'
    | 'stars-4.5'
    | 'stars-5.0'
    | 'truck'
    | 'legacy/exclamation-circle'
    | 'legacy/chevron-left'
    | 'autoship'
    | 'autoship-light'
    | 'autoship-icon-light-bg'
    | 'autoship-icon-dark-bg'
    | 'autoship-badge'
    | 'thumbs-up'
    | 'edit-icon'
    | 'delete-icon'
    | any // TODO: find better way, this was added for `stars-${rating}` type fix
}

export default function StandardIcon(props: {
  icon: Design['icon']
  alt: string
  width: number
  height: number
  className?: string
}) {
  return (
    <Image
      src={`/icon/${props.icon}.svg`}
      alt={props.alt}
      width={props.width}
      height={props.height}
      className={props.className}
    />
  )
}
