export default function LegacyExclamationIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="-10 0 898 1024">
      <path
        fill="currentColor"
        d="M439 82q91 0 171 35q80 34 139.5 93.5t93.5 139.5q35 80 35 171t-35 171q-34 80 -93.5 139.5t-139.5 93.5q-80 35 -171 35t-171 -35q-80 -34 -139.5 -93.5t-94.5 -139.5q-34 -80 -34 -171t34 -171q35 -80 94.5 -139.5t139.5 -93.5q80 -35 171 -35v0zM512 795v-109
q0 -7 -5 -13t-13 -6h-109q-8 0 -13.5 6t-5.5 13v109q0 8 5.5 13.5t13.5 5.5h109q8 0 13 -5.5t5 -13.5zM511 598l10 -355q0 -3 -1.5 -5.5t-4.5 -4.5q-2 -2 -5.5 -3t-7.5 -1h-126q-4 0 -7.5 1t-6.5 3t-4 4.5t-1 5.5l9 355q0 6 6 10.5t14 4.5h105q8 0 13.5 -4.5t6.5 -10.5v0z
"
      />
    </svg>
  )
}
