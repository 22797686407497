import clsx from 'clsx'
import type { ReactNode } from 'react'
import { appendRegularPluralSuffix } from '../../lib/utils/grammar'
import StandardIcon from './icons/StandardIcon'

function getNearestRating(n: number) {
  // We can be sure that the string split will always have at least 1 element
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return (parseFloat((n * 2 + 0.5).toString().split('.')[0]!) / 2).toFixed(1)
}

function DefaultReviews(props: { count: number; rating: number }) {
  return (
    <div className="leading-0 mt-2 text-sm font-semibold text-primary-900 sm:ml-2 sm:mt-0">
      {props.rating} {appendRegularPluralSuffix('Review', props.count)}
    </div>
  )
}

export type StarRatingProps = {
  className?: string
  rating: string
  count?: string
  renderReviews?: (props: { count: number; rating: number }) => ReactNode
  starContainerClassName?: string
}

export function StarRating({
  className,
  rating,
  count,
  renderReviews = ({ count, rating }) => <DefaultReviews count={count} rating={rating} />,
  starContainerClassName,
}: StarRatingProps) {
  const ratingNumber = parseFloat(rating)
  const ratingNearest = getNearestRating(ratingNumber)
  const ratingCount = count ? parseInt(count, 10) : null

  return (
    <div className={clsx('flex flex-col sm:flex-row sm:items-center', className)}>
      <div className={clsx('flex items-center', starContainerClassName)}>
        <StandardIcon
          icon={`stars-${ratingNearest}` as any}
          alt={`${ratingNearest} stars`}
          width={100}
          height={18}
        />
      </div>
      {ratingCount ? renderReviews({ count: ratingCount, rating: ratingNumber }) : null}
    </div>
  )
}
