import clsx from 'clsx'
import type { ReactNode } from 'react'
import AUTOSHIP from '../../../lib/constants/AUTOSHIP'
import StandardIcon from '../../global/icons/StandardIcon'

export function NewProductBadge({ className }: { className?: string }) {
  return (
    <BaseProductBadge className={clsx('bg-purple-400 text-white', className)}>New</BaseProductBadge>
  )
}

export function DealProductBadge({ className }: { className?: string }) {
  return (
    <BaseProductBadge className={clsx('bg-banana text-pebble', className)}>Deal</BaseProductBadge>
  )
}

export function AutoshipProductBadge({ className }: { className?: string }) {
  return (
    <BaseProductBadge
      className={clsx('flex items-center justify-center space-x-1 bg-plum text-white', className)}
    >
      <StandardIcon icon="autoship-light" alt="" width={16} height={16} />
      <p className="inline-block text-xs sm:text-sm">
        Save {AUTOSHIP.promotions.recurring.discount.percentage}%
      </p>
    </BaseProductBadge>
  )
}

export type ProductBadgeProps = {
  className?: string
  isAutoshipReccuring?: boolean
  isDeal?: boolean
  isNew?: boolean
}

export function ProductBadge({ className, isAutoshipReccuring, isDeal, isNew }: ProductBadgeProps) {
  if (isAutoshipReccuring) {
    return <AutoshipProductBadge className={className} />
  } else if (isDeal) {
    return <DealProductBadge className={className} />
  } else if (isNew) {
    return <NewProductBadge className={className} />
  } else {
    return null
  }
}

export default function BaseProductBadge({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) {
  return (
    <span
      className={clsx(
        'absolute top-0 left-0 z-10 rounded-tl-md rounded-br-xl px-2 py-1 text-xs font-bold uppercase tracking-wide sm:text-sm',
        className
      )}
    >
      {children}
    </span>
  )
}
