function loggingEnabled() {
  if (process.browser) {
    // On the browser side, only enable logging should __carewellLogging be true (defaults to process.env.LOGGING_ENABLED)
    return window.__carewellLogging
  }

  // Enable server-side logging by default
  return !['production', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV || '')
}

function getLoggingAreas() {
  // LOG_AREA=v3customer,apiAccountCreate npm run dev
  return process.env.LOG_AREA?.split(',')
}

function log(fn: 'log' | 'warn' | 'error' | 'debug', ...args) {
  if (!loggingEnabled()) return

  // logger.debug(`${areaName}`, ..args)
  const loggingArea = getLoggingAreas()

  if (
    (!process.browser && !loggingArea) ||
    (!loggingArea?.includes(args[0]) && !loggingArea?.includes('all'))
  ) {
    return
  }

  if (process.browser) {
    // eslint-disable-next-line no-console
    return console.log(...args)
  } else {
    // eslint-disable-next-line no-console
    return console.log(JSON.stringify(args, null, 2))
  }
}

const logger = {
  info: (...args) => {
    log('log', ...args)
  },
  warn: (...args) => {
    log('warn', ...args)
  },
  error: (...args) => {
    log('error', ...args)
  },
  debug: (...args) => {
    log('debug', ...args)
  },
}

/**
 * @deprecated
 */
export default logger
