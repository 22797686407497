export default {
  promotional: {
    label: 'FREE',
    threshold: 49,
    dayEstimate: {
      start: 1,
      end: 2,
    },
  },
}
