import type { FlattenedGQLCategoryTreeItem } from '../../../utils/categories'
import type { DataLayerItemCategories } from './DataLayerTypes'

export default function getGTMItemCategoriesFromBreadcrumbs(
  breadcrumbs?: FlattenedGQLCategoryTreeItem
): DataLayerItemCategories {
  if (!breadcrumbs) return {}

  const categoryNames = [...breadcrumbs.parents.map((p) => p.name), breadcrumbs.name]

  const categories = {
    item_category: categoryNames[0],
    item_category2: categoryNames[1],
    item_category3: categoryNames[2],
    item_category4: categoryNames[3],
    item_category5: categoryNames[4],
  }

  const keys = Object.keys(categories)

  // Avoid any json/undefined issues en route to GA4
  keys.forEach((k) => {
    if (!categories[k]) delete categories[k]
  })

  return categories
}
