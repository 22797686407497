import { getCustomerSegments } from '../lib/utils/getCustomerSegments'
import useCustomerQuery from './useCustomerQuery'

/**
 * A hook that determines if a logged in customer already has an existing
 * or a previous cancelled Autoship order.
 */
export default function useCustomerHasAutoshipSubscriptions() {
  const { data: customer } = useCustomerQuery()

  const loggedIn = Boolean(customer?.id)
  const { hasActiveAutoships, hasCancelledAutoship } = getCustomerSegments(customer?.groupId ?? 0)
  return loggedIn && (hasActiveAutoships || hasCancelledAutoship)
}
