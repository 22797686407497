import useDataLayerPush from 'lib/clientOnly/analytics/gtm/useDataLayerPush'
import type { ReactNode } from 'react'
import LegacyExclamationIcon from '../legacy/icons/LegacyExclamationIcon'

function formatError(error: unknown, label?: string) {
  if (error) {
    if (typeof error === 'string') return error

    if (typeof error === 'object') {
      if ('message' in error && typeof error.message === 'string') return error.message
      if ('type' in error && error.type === 'required') {
        return `${label ? label : 'This'} field is required`
      }
    }

    return 'Unknown Error'
  }

  return ''
}

export function CustomFormError({
  analyticsAction,
  children,
}: {
  analyticsAction?: string
  children: ReactNode
}) {
  return (
    <div
      role="alert"
      className="flex space-x-2 font-semibold leading-tight text-red-dark"
      {...(analyticsAction ? { 'data-cw-track': analyticsAction } : {})}
    >
      <LegacyExclamationIcon className="mt-0.5 h-4 w-4 flex-none" />
      <span>{children}</span>
    </div>
  )
}

export function FormError({
  error,
  label,
  analyticsAction,
}: {
  error: unknown
  label?: string // field label text for clearer error message and tracking
  analyticsAction?: string
}) {
  const push = useDataLayerPush()
  !!error &&
    push({
      event: {
        name: 'formError',
        category: `${error}`.indexOf('cart') > -1 ? 'Add to Cart Error' : `${error}`,
        label: label,
      },
    })
  const errorMessage = error ? formatError(error, label) : null
  if (!errorMessage) return null

  return <CustomFormError analyticsAction={analyticsAction}>{errorMessage}</CustomFormError>
}
