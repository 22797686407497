import { useEffect } from 'react'

export default function useWindowResizeListener(fn: (width: number, height: number) => void) {
  useEffect(() => {
    function handleResize() {
      fn(window.innerWidth, window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [fn])
}
