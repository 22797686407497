import { publicConfig } from '../publicConfig'
import type { AutoshipConstT } from './types'

const AUTOSHIP: AutoshipConstT = {
  promotions: {
    first: {
      discount: {
        percentage: 30,
        dollarMax: 20,
      },
    },
    recurring: {
      discount: {
        percentage: 5,
      },
      categories: {
        included: [publicConfig.categoryIdentifiers.autoshipSave],
        excluded: [publicConfig.categoryIdentifiers.autoshipExclusion],
      },
    },
  },
}

export default AUTOSHIP
