/**
 * These are the default tailwind breakpoints.
 *
 * Shared with tailwind.config.js we can then access the breakpoints in JS code e.g.
 * for use within window resize events
 */

const defaultTheme = require('tailwindcss/defaultTheme')

function extractDefaultBreakpoint(breakpoint) {
  return parseInt(defaultTheme.screens[breakpoint].replace('px', ''), 10)
}

const breakpoints = {
  xs: 375,
}

Object.keys(defaultTheme.screens).forEach((bp) => {
  breakpoints[bp] = extractDefaultBreakpoint(bp)
})

module.exports = breakpoints
