import type {
  BigCommerceGQLCategoryTree3LevelsDeep,
  BigCommerceGQLCategoryTreeItem,
} from '../serverOnly/api/bigcommerce/graphql/catalog'
import compact from './compact'
import getOccurrences from './getOccurrences'
import last from './last'

type CategoryWithPath = {
  name?: string
  path?: string
}

function getNamesFromPathFormattedCategories(categories: Array<CategoryWithPath>) {
  const categoriesWithSlugs = compact(
    categories.map((c) => {
      if (c.name && c.path) {
        const slugs = compact(c.path.split('/'))

        return {
          name: c.name,
          slug: last(slugs),
          parentSlugs: slugs.slice(0, slugs.length - 1),
        }
      }
    })
  )

  const categoryNames = categoriesWithSlugs
    .map((c) => {
      return [
        ...compact(c.parentSlugs.map((p) => categoriesWithSlugs.find((pp) => pp.slug === p))).map(
          (p) => p.name
        ),
        c.name,
      ]
    })
    .flat()
  return categoryNames
}

// Get the primaryCategory & secondaryCategory given an ORDERED list of categories
// The order is important & can be configured via BigCommerce dashboard
// For example - the Collections category should be lower than e.g. Healthcare to ensure that Healthcare is selected
// as the primary category rather than Collections
export default function getMainCategories(categories: Array<CategoryWithPath>) {
  const categoryNames = getNamesFromPathFormattedCategories(categories)
  const occurrences = getOccurrences(categoryNames)
  const mostFrequentCategory = occurrences[0]
  // secondaryCategory must have fewer occurances than the primaryCategory
  const remainingOccurrences = mostFrequentCategory
    ? occurrences.slice(1).filter((o) => o[1] < mostFrequentCategory[1])
    : []
  const secondMostFrequentCategory = remainingOccurrences[0]?.[0] ?? null

  return {
    primaryCategory: mostFrequentCategory?.[0] ?? null,
    secondaryCategory: secondMostFrequentCategory,
  }
}

export type FlattenedTreeCategoriesItem = {
  names: Array<string | undefined>
  path?: string
  entityId?: number
  parentId?: number
  ssrParents: BigCommerceGQLCategoryTreeItem[]
  ssrChildren?: FlattenedTreeCategoriesItem[]
}

export type FlattenedGQLCategoryTreeItem = {
  parents: BigCommerceGQLCategoryTreeItem[]
} & BigCommerceGQLCategoryTreeItem

export function getFlattenedGQLTree(categoryTree: BigCommerceGQLCategoryTree3LevelsDeep) {
  function flatten(
    parent: FlattenedGQLCategoryTreeItem | null,
    collection: BigCommerceGQLCategoryTreeItem[],
    flattened: FlattenedGQLCategoryTreeItem[]
  ) {
    collection.forEach((bcGqlItem) => {
      const flatItem = {
        ...bcGqlItem,
        parents: [...(parent?.parents || []), ...((parent && [parent]) || [])],
      } as FlattenedGQLCategoryTreeItem

      flattened.push(flatItem)
      flatten(flatItem, bcGqlItem.children || [], flattened)
    })
    return flattened
  }

  return flatten(null, categoryTree, [])
}
