import { useCallback, useState } from 'react'
import useWindowResizeListener from './useWindowResizeListener'

export default function useWindowSize() {
  const [size, setSize] = useState<{ width?: number; height?: number }>({
    width: typeof window !== 'undefined' ? window.innerWidth : undefined,
    height: typeof window !== 'undefined' ? window.innerHeight : undefined,
  })

  useWindowResizeListener(
    useCallback((width, height) => {
      setSize({ width, height })
    }, [])
  )

  return size
}
