import { useRef } from 'react'
import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'
import logger from '../lib/utils/log'

type FetchConfig = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  body?: BodyInit | null
  headers?: HeadersInit | null
}

type ApiResponse = {
  legacyCartSyncURL?: string
}

/**
 * By default useApiQuery will check for .valid=true i.e.. for usage with internal carewell api endpoints
 */
function defaultParseJson(jsonData: any, res: Response) {
  if (jsonData.valid) {
    return jsonData
  } else {
    const path = new URL(res.url).pathname
    throw new Error(`${path} - ${res.status}: ${jsonData.message || res.statusText}`)
  }
}

async function fetcher([url, getFetchConfig, parser]: [
  url: string,
  getFetchConfig: () => FetchConfig,
  parser: any
]) {
  const { method = 'GET', body, headers } = getFetchConfig?.() ?? {}
  const res = await fetch(url, {
    method,
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  })

  const jsonData = await res.json()

  return parser(jsonData, res)
}

/**
 * This hook is a simple wrapper around useSWR used for async fetching data from our API.
 * Data will be refetched if url or arguments change.
 *
 * @example
 * const {data, error, loading} = useAPIQuery("/api/endpoint")
 *
 * if (error) return <Error/>
 * if (loading) return <LoadingIndicator/>
 * return <Component data={data}/>
 *
 * @param url
 * @param params
 */
export function useApiQuery<Data = any | ApiResponse, Error = any>(
  url: string | null,
  {
    getFetchConfig,
    onSuccess,
    enabled = true,
    parser = defaultParseJson,
    keepPreviousData = false,
    fallbackData,
    mutateResponseBody,
    ...config
  }: SWRConfiguration<Data, Error> & {
    parser?
    getFetchConfig?: () => FetchConfig
    enabled?: boolean
    keepPreviousData?: boolean
    mutateResponseBody?: (args: Data) => Data
  } = {}
) {
  const ref = useRef<Data | null>(fallbackData || null)

  // TODO: Support json arguments
  const swr = useSWR<Data, Error>(enabled && url ? [url, getFetchConfig, parser] : null, fetcher, {
    onSuccess: (data, ...args) => {
      const config = getFetchConfig?.()
      const nextData = mutateResponseBody ? mutateResponseBody(data) : data
      const resolvedMethod = config?.method || 'GET'
      const loggerArguments: any[] = [`${resolvedMethod} ${url}`]
      if (resolvedMethod === 'POST' && config?.body) {
        loggerArguments.push(config?.body)
      }
      logger.debug(...loggerArguments, nextData)
      onSuccess?.(nextData, ...args)
      // handle syncing headless cart to legacy site
      // If legacyCartSyncURL is returned in response sync the cart
      // TODO: properly type
      if (nextData && nextData['legacyCartSyncURL']) {
        fetch(nextData['legacyCartSyncURL'], {
          redirect: 'manual',
        })
      }
    },
    ...config,
  })

  if (swr.data) {
    ref.current = swr.data
  }

  return {
    ...swr,
    data: keepPreviousData ? ref.current : swr.data,
  }
}
